<template>
	<div>
		<div v-if="showError!==''" style="background-color:red;color:white;">
			{{ showError }}
		</div>
		<v-card>
			<v-card-title					
				v-if="access>=1"
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					title="Search/Filter users"
					single-line
					hide-details
				></v-text-field>
				<v-icon
					medium
					class="mr-2"
					@click="addItem()"
					color="green"
					title="Add a new user"
					style="margin-left:20px;"
				>
					mdi-plus
				</v-icon>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredData"
				:items-per-page="15"				
				:search="search"
			>
				<template v-slot:item="{ item}">
					<tr :data-id="item.id" style="text-align:left;">
						<td v-if="!item.deleted">{{ item._id }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item._id }}</td>
						<td v-if="!item.deleted">{{ item.deviceType }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.deviceType }}</td>
						<td v-if="!item.deleted">{{ getOwnerNames(item.owner) }}</td>
						<td v-if="item.deleted" style="color:red;">{{ getOwnerNames(item.owner) }}</td>
						<td v-if="!item.deleted">{{ item.name }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.name }}</td>
						<td v-if="!item.deleted">{{ item.ip }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.ip }}</td>
						<td>
							<v-icon
								medium
								class="mr-2"
								@click="editItem(item)"
								color="green"
								:title="'Edit detector '+item._id"
							>
								mdi-border-color
							</v-icon>
							<v-icon
								medium
								v-if="item._id && item._id!=='' && !item.deleted"
								@click="deleteItem(item)"
								:title="'Delete detector '+item._id"
							>
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>	
		<v-row>
			<v-col v-if="access>=2" style="margin-top:-10px;">
				<v-checkbox
					v-model="showDeleted"
					label="Show Deleted"
					@change="getDetectors"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-dialog
			v-model="addDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Add detector</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="detectorMac"
								label="MAC Address"
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedDeviceType"
								label="Device Type"
								:items="deviceType"
								item-text="label"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredEntityData"
								item-text="name"
								item-value="_id"
								multiple
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-45px;">
							<v-text-field
								v-model="detectorName"
								label="Name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
					</v-row>					
					<v-card-actions>
						<v-row>
							<v-col cols=12 style="text-align:right">
								<v-btn
									text
									@click="saveDetector"
								>Save</v-btn>
								<v-btn
									text
									@click="addDialog = false"
								>Close</v-btn>
							</v-col>
							<v-col v-if="saveErrorMessage!==''" cols=12  style="text-align:left; background-color:red;">
								{{saveErrorMessage}}
							</v-col>
						</v-row>					
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
		<v-dialog
			v-model="editDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Edit detector</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="detectorMac"
								label="MAC Address (Read Only)"
								readonly
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedDeviceType"
								label="Device Type"
								:items="deviceType"
								item-text="label"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredEntityData"
								item-text="name"
								item-value="_id"
								multiple
							></v-select>
						</v-col>
						<v-col cols=12>
							<v-text-field
								v-model="detectorName"
								label="Name"
								item-text="name"
								item-value="name"
								style="margin-top:-35px;"
							></v-text-field>
						</v-col>
						<v-col cols=6 v-if="wasDeleted" style="margin-top:-45px;">
							<v-checkbox
								v-model="itemDeleted"
								label="Deleted"
							></v-checkbox>
						</v-col>						
					</v-row>					
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="updateDetector"
						>Save</v-btn>
						<v-btn
							text
							@click="editDialog = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
	</div>
</template>
<script>
	import detector from '@/services/detector.js'
	export default {
		name: 'detector',

		components: {
		},
		data: () => ({
			saveErrorMessage:'',
			password:'',
			showPassword:false,
			confirmPassword:'',
			showConfirmPassword:false,
			detectorMac:"",
			search: '',
			accessLevels: [
				{ index:0, level:"User" },
				{ index:1, level:"Administrator" },
				{ index:2, level:"System Administrator" },
			],
			deviceType: [
				{ id: 'Barcode', label: 'Barcode Reader'},
				{ id: 'BLE', label: 'BLE'},
				{ id: 'GNSS', label: 'GNSS'},
				{ id: 'GPS', label: 'GPS'},
				{ id: 'QR', label: 'QR Code Reader'},
				{ id: 'RFID', label: 'RFID Reader'},
			],
			selectedDeviceType:[],
			accessLevel:{},
			headers: [
				{ text: 'MAC', value: '_id' },
				{ text: 'Device Type', value: 'deviceType' },
				{ text: 'Owner', value: 'owner' },
				{ text: 'Name', value: 'name' },
				{ text: 'IP Address', value: 'ip' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			rowsAmount: [15, 20, 25, 50, -1],
			detectorList: [],
			footer:{
				'items-per-page-options':[15, 20, 25, 50, -1]
			},		  
			addDialog: false,
			newSaveError:"",
			editDialog: false,
			selectedDetector: {},
			selectedEntity:[],
			entityList:[],
			currentDetector: {},
			selfOwner: false,
			ownerid:'',
			detectorName: "",
			superUser: true,
			deleteError:'',
			
			showDeleted:false,
			
			itemDeleted:false,
			wasDeleted:false,
			showError:"",
			id:"",
		}),
		methods: {
			getAccessLevel(level){
				var result="";
				if (!level) level=0;
				this.accessLevels.forEach(function(item){
					if (item.index===level)
						result=item.level;
				})
				
				return result;
			},
			addItem: function() {
				this.detectorMac="";
				this.selectedDetector={};
				this.addDialog=true;
				this.ownerid='';
				this.detectorName="";
				this.password="";
				this.selectedEntity=[];
				this.selectedDeviceType={};
				this.showPassword=false;
				this.confirmPassword="";
				this.showConfirmPassword=false;
				this.saveErrorMessage="";
			},
			editItem: function(item) {
				this.currentDetector=item;
				this.editDialog=true;
				this.selectedEntity=item.owner;
				this.detectorMac=item._id;
				if (item.deleted)
				{
					this.wasDeleted=true;
					this.itemDeleted=true;
				}
				else
				{
					this.wasDeleted=false;
					this.itemDeleted=false;
				}
				this.selectedDeviceType=item.deviceType;
				this.detectorName=item.name;
				this.id=item.id;
				this.password=item.password;
				this.showPassword=false;
				this.confirmPassword=item.password;
				this.showConfirmPassword=false;
				this.saveErrorMessage="";
			},
			selectedOwner: function(item) {
				this.ownerid=item._id;
			},
			saveDetector: function()
			{
				this.saveErrorMessage="";
				var valid=true;
				var detectorData={};
				if (this.detectorMac==="")
				{
					valid=false;
					this.saveErrorMessage="MAC address required";
				}
				else
				{
					detectorData._id=this.detectorMac;
				}
					
				if (valid)
				{
					if (!this.selectedEntity)
					{
						valid=false;
						this.saveErrorMessage="Owner required, please make sure all fields are completed before saving again.";
					}
					else
						detectorData.owner=this.selectedEntity;
				}
				if (valid)
				{
					if (!this.detectorName)
					{
						valid=false;
						this.saveErrorMessage="User name required, please make sure all fields are completed before saving again.";
					}
					else
						detectorData.name=this.detectorName;
				}
				if (valid)
				{
					if (!this.selectedDeviceType)
					{
						valid=false;
						this.saveErrorMessage="Device type required, please make sure all fields are completed before saving again.";
					}
					else
						detectorData.deviceType=this.selectedDeviceType;
				}
					
				if (valid)
				{
					detector.addDetector(detectorData, this.$store.getters.token).then(response => {
						if (response && response.data && response.data.status )
						{
							if (response.data.status==="ok" && response.data.access_token)
							{
								this.$store.commit('setToken', response.data.access_token);
								this.addDialog=false;
								this.getDetectors();
							}
							else
							{
								if (response.data.status==="error")
									this.saveErrorMessage=response.data.message;
							}
						}
						else
						{
							this.$store.commit('LogOut');
						}
					})
					.catch(error => {
						console.log(error);
						this.saveErrorMessage=error;
					});
				}
			},			
			updateDetector: function()
			{
				var detectorData={};
				detectorData.id=this.detectorMac;
				detectorData.name=this.detectorName;
				detectorData.deviceType=this.selectedDeviceType;
				detectorData.owner=this.selectedEntity;
				if (this.wasDeleted&&!this.itemDeleted)
					detectorData.deleted=false;
					
				detector.updateDetector(detectorData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.editDialog=false;
						this.getDetectors();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			deleteItem: function(item) {
				detector.deleteDetector(item._id, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						//this.addDialog=false;
						this.getDetectors();
					}
					else
					{
						if (response && response.data && response.data.status && response.data.message)
						{
							this.showError=response.data.message
						}
						else
							this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.deleteError=error;
				});
			},
			changed: function(currentSelection){
				this.selectedTab=currentSelection
			},
			getDetectors: function() {
				detector.detectors(this.$store.getters.token, this.showDeleted).then(response => {
					if (response && response.data && response.data.Detectors)
					{
						this.detectorList=response.data.Detectors;
						console.log(response.data.Detectors);
						if (response.data.entities&&response.data.entities.length>0)
							this.entityList=response.data.entities;
						else
							this.entityList=[{_id:response.data.users[0].entity,name:response.data.users[0].ownerName}];
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			getOwnerName: function(item)
			{
				var result="";
				console.log(this.entityList);
				for (var i=0; i<this.entityList.length&&result===""; i++)
				{
					if (this.entityList[i]._id===item)
						result=this.entityList[i].name;
				}
				return result;
			},
			getOwnerNames: function(item)
			{
				var result="";
				if (item)
				{
					if (item.length)
					{
						for (var i=0;i<item.length;i++)
						{
							if (item[i]!=="")
							{
								if (result!="") result+=", ";
								result+=this.getOwnerName(item[i]);
							}
						}
					}
					else
						result=this.getOwnerName(item);
				}
					
				return result;
			}
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
			filteredData(){
				var result=this.detectorList.filter(item => (item.deleted !== true || this.showDeleted));
				return result;
			},
			filteredEntityData(){
				var result=this.entityList.filter(item => (!item.deleted));
				return result;
			},
			filteredAccessLevel(){
				var result=this.accessLevels.filter(item => (item.index <= this.$store.state.access));
				return result;
			},
		},
		created() {
			document.title="Tract - Detector Settings";
			this.getDetectors();
		}
	}
</script>
