import axios from 'axios'

export default {
	beacons(token, showDeleted) {
		const headers = {};		
		var options={};
		
		if (token)
			headers.authorization='Bearer ' + token;
		if (showDeleted)
			options.showdeleted=showDeleted;
		return axios.post('/tract/api/beacons', options, {headers:headers});
	},	
	addBeacon(beacon, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/beacon/new', beacon, {headers:options});
	},		
	updateBeacon(beacon, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/beacon/update', beacon, {headers:options});
	},		
	deleteBeacon(id, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/beacon/delete', {id:id}, {headers:options});
	},		
}	