<template>
	<div>
		<div v-if="showError!==''" style="background-color:red;color:white;">
			{{ showError }}
		</div>
		<v-card>
			<v-card-title					
				v-if="access>=1"
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					title="Search/Filter users"
					single-line
					hide-details
				></v-text-field>
				<v-icon
					medium
					class="mr-2"
					@click="addItem()"
					color="green"
					title="Add a new user"
					style="margin-left:20px;"
				>
					mdi-plus
				</v-icon>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="filteredData"
				:items-per-page="15"				
				:search="search"
			>
				<template v-slot:item="{ item}">
					<tr :data-id="item.id" style="text-align:left;">
						<td v-if="!item.deleted">{{ item._id }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item._id }}</td>
						<td v-if="!item.deleted">{{ item.type }} 
							<v-icon v-if="item.battery>95" color="green">mdi-battery</v-icon>
							<v-icon v-if="item.battery>=85&&item.battery<95" color="green">mdi-battery-90</v-icon>
							<v-icon v-if="item.battery>=75&&item.battery<85" color="green">mdi-battery-80</v-icon>
							<v-icon v-if="item.battery>=65&&item.battery<75">mdi-battery-70</v-icon>
							<v-icon v-if="item.battery>=55&&item.battery<65">mdi-battery-60</v-icon>
							<v-icon v-if="item.battery>=45&&item.battery<55">mdi-battery-50</v-icon>
							<v-icon v-if="item.battery>=35&&item.battery<45" color="red">mdi-battery-40</v-icon>
							<v-icon v-if="item.battery>=25&&item.battery<35" color="red">mdi-battery-30</v-icon>
							<v-icon v-if="item.battery>=15&&item.battery<25" color="red">mdi-battery-20</v-icon>
							<v-icon v-if="item.battery>= 5&&item.battery<15" color="red">mdi-battery-10</v-icon>
							<v-icon v-if="item.battery<5" color="red">mdi-battery-alert-variant-outline</v-icon>
							<v-icon v-if="item.longitude&&item.longitude!==0.000000&&item.latitude!==0.000000" title="Position">mdi-crosshairs-gps</v-icon>
							<v-icon v-if="item.temperature" title="Temperature">mdi-temperature-celsius</v-icon>
							<v-icon v-if="item.humidity" title="Humidity">mdi-water</v-icon>
							<v-icon v-if="item.light>=0" title="Light Detection">mdi-lightbulb-outline</v-icon>
							<v-icon v-if="item.switch>=0||item.tamperSwitch>=0" title="Switch">mdi-electric-switch</v-icon>
						</td>
						<td v-if="item.deleted" style="color:red;">{{ item.type }} 
							<v-icon v-if="item.battery>95" color="green">mdi-battery</v-icon>
							<v-icon v-if="item.battery>=85&&item.battery<95" color="green">mdi-battery-90</v-icon>
							<v-icon v-if="item.battery>=75&&item.battery<85" color="green">mdi-battery-80</v-icon>
							<v-icon v-if="item.battery>=65&&item.battery<75">mdi-battery-70</v-icon>
							<v-icon v-if="item.battery>=55&&item.battery<65">mdi-battery-60</v-icon>
							<v-icon v-if="item.battery>=45&&item.battery<55">mdi-battery-50</v-icon>
							<v-icon v-if="item.battery>=35&&item.battery<45" color="red">mdi-battery-40</v-icon>
							<v-icon v-if="item.battery>=25&&item.battery<35" color="red">mdi-battery-30</v-icon>
							<v-icon v-if="item.battery>=15&&item.battery<25" color="red">mdi-battery-20</v-icon>
							<v-icon v-if="item.battery>= 5&&item.battery<15" color="red">mdi-battery-10</v-icon>
							<v-icon v-if="item.battery<5" color="red">mdi-battery-alert-variant-outline</v-icon>
							<v-icon v-if="item.longitude&&item.longitude!==0.000000&&item.latitude!==0.000000" title="Position">mdi-crosshairs-gps</v-icon>
							<v-icon v-if="item.temperature" title="Temperature">mdi-temperature-celsius</v-icon>
							<v-icon v-if="item.humidity" title="Humidity">mdi-water</v-icon>
							<v-icon v-if="item.light>=0" title="Light Detection">mdi-lightbulb-outline</v-icon>
							<v-icon v-if="item.switch>=0||item.tamperSwitch>=0" title="Switch">mdi-electric-switch</v-icon>
						</td>
						<td v-if="!item.deleted">{{ getOwnerNames(item.owner) }}</td>
						<td v-if="!item.deleted">{{ getDateTime(item.lastupdate) }}</td>
						<td v-if="item.deleted" style="color:red;">{{ getDateTime(item.lastupdate) }}</td>
						<td v-if="item.deleted" style="color:red;">{{ getOwnerNames(item.owner) }}</td>
						<td v-if="!item.deleted">{{ item.name }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.name }}</td>
						<td>
							<v-icon
								medium
								class="mr-2"
								@click="editItem(item)"
								color="green"
								:title="'Edit beacon '+item._id"
							>
								mdi-border-color
							</v-icon>
							<v-icon
								medium
								v-if="item._id && item._id!=='' && !item.deleted"
								@click="deleteItem(item)"
								:title="'Delete beacon '+item._id"
							>
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>	
		<v-row>
			<v-col v-if="access>=2" style="margin-top:-10px;">
				<v-checkbox
					v-model="showDeleted"
					label="Show Deleted"
					@change="getBeacons"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-dialog
			v-model="addDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Add beacon</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="beaconMac"
								label="MAC Address"
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedDeviceType"
								label="Device Type"
								:items="deviceType"
								item-text="label"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredEntityData"
								item-text="name"
								item-value="_id"
								multiple
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-45px;">
							<v-text-field
								v-model="beaconName"
								label="Name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
					</v-row>					
					<v-card-actions>
						<v-row>
							<v-col cols=12 style="text-align:right">
								<v-btn
									text
									@click="saveBeacon"
								>Save</v-btn>
								<v-btn
									text
									@click="addDialog = false"
								>Close</v-btn>
							</v-col>
							<v-col v-if="saveErrorMessage!==''" cols=12  style="text-align:left; background-color:red;">
								{{saveErrorMessage}}
							</v-col>
						</v-row>					
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
		<v-dialog
			v-model="editDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Edit beacon</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="beaconMac"
								label="MAC Address (Read Only)"
								readonly
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedDeviceType"
								label="Device Type"
								:items="deviceType"
								item-text="label"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredEntityData"
								item-text="name"
								item-value="_id"
								multiple
							></v-select>
						</v-col>
						<v-col cols=12>
							<v-text-field
								v-model="beaconName"
								label="Name"
								item-text="name"
								item-value="name"
								style="margin-top:-35px;"
							></v-text-field>
						</v-col>
						<v-col cols=12 v-if="wasDeleted" style="margin-top:-45px;">
							<v-checkbox
								v-model="itemDeleted"
								label="Deleted"
							></v-checkbox>
						</v-col>
						<v-col cols=3 v-if="currentBeacon.battery" style="margin-top:-25px;text-align:left;">
							Battery 
						</v-col>
						<v-col cols=9 v-if="currentBeacon.battery" style="margin-top:-25px;text-align:left;">
							<v-icon v-if="currentBeacon.battery>95" color="green">mdi-battery</v-icon>
							<v-icon v-if="currentBeacon.battery>=85&&currentBeacon.battery<95" color="green">mdi-battery-90</v-icon>
							<v-icon v-if="currentBeacon.battery>=75&&currentBeacon.battery<85" color="green">mdi-battery-80</v-icon>
							<v-icon v-if="currentBeacon.battery>=65&&currentBeacon.battery<75">mdi-battery-70</v-icon>
							<v-icon v-if="currentBeacon.battery>=55&&currentBeacon.battery<65">mdi-battery-60</v-icon>
							<v-icon v-if="currentBeacon.battery>=45&&currentBeacon.battery<55">mdi-battery-50</v-icon>
							<v-icon v-if="currentBeacon.battery>=35&&currentBeacon.battery<45" color="red">mdi-battery-40</v-icon>
							<v-icon v-if="currentBeacon.battery>=25&&currentBeacon.battery<35" color="red">mdi-battery-30</v-icon>
							<v-icon v-if="currentBeacon.battery>=15&&currentBeacon.battery<25" color="red">mdi-battery-20</v-icon>
							<v-icon v-if="currentBeacon.battery>= 5&&currentBeacon.battery<15" color="red">mdi-battery-10</v-icon>
							<v-icon v-if="currentBeacon.battery<5" color="red">mdi-battery-alert-variant-outline</v-icon>
							{{currentBeacon.battery}}% <div v-if="currentBeacon.batteryVoltage>0" style="display:inline-block">{{currentBeacon.batteryVoltage}}V</div>
						</v-col>
						<v-col cols=3 v-if="currentBeacon.longitude&&currentBeacon.longitude!==0.000000&&currentBeacon.latitude!==0.000000" style="margin-top:-15px;text-align:left;">
							Position 
						</v-col>
						<v-col cols=9 v-if="currentBeacon.longitude&&currentBeacon.longitude!==0.000000&&currentBeacon.latitude!==0.000000" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-crosshairs-gps</v-icon>
							Longitude {{currentBeacon.longitude}}  Latitude {{currentBeacon.latitude}}
						</v-col>
						<v-col cols=3 v-if="currentBeacon.temperature" style="margin-top:-15px;text-align:left;">
							Temperature 
						</v-col>
						<v-col cols=9 v-if="currentBeacon.temperature" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-temperature-celsius</v-icon>
							{{currentBeacon.temperature}}
						</v-col>
						<v-col cols=3 v-if="currentBeacon.humidity" style="margin-top:-15px;text-align:left;">
							Humidity 
						</v-col>
						<v-col cols=9 v-if="currentBeacon.humidity" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-water</v-icon>
							{{currentBeacon.humidity}}
						</v-col>
						<v-col cols=3 v-if="currentBeacon.light>=0" style="margin-top:-15px;text-align:left;">
							Light Sensor
						</v-col>
						<v-col cols=9 v-if="currentBeacon.light==0" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-lightbulb-outline</v-icon>
							Dark
						</v-col>
						<v-col cols=9 v-if="currentBeacon.light==1" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-lightbulb</v-icon>
							Light
						</v-col>
						<v-col cols=3 v-if="currentBeacon.switch>=0" style="margin-top:-15px;text-align:left;">
							Switch
						</v-col>
						<v-col cols=9 v-if="currentBeacon.switch==0" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-electric-switch</v-icon>
							Off
						</v-col>
						<v-col cols=9 v-if="currentBeacon.switch==1" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-electric-switch-closed</v-icon>
							On
						</v-col>
						<v-col cols=3 v-if="currentBeacon.tamperSwitch>=0" style="margin-top:-15px;text-align:left;">
							Tamper Switch
						</v-col>
						<v-col cols=9 v-if="currentBeacon.tamperSwitch==0" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-electric-switch</v-icon>
							Off
						</v-col>
						<v-col cols=9 v-if="currentBeacon.tamperSwitch==1" style="margin-top:-15px;text-align:left;">
							<v-icon>mdi-electric-switch-closed</v-icon>
							On
						</v-col>
						
						
					</v-row>					
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="updateBeacon"
						>Save</v-btn>
						<v-btn
							text
							@click="editDialog = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
	</div>
</template>
<script>
	import beacon from '@/services/beacon.js'
	export default {
		name: 'beacon',

		components: {
		},
		data: () => ({
			saveErrorMessage:'',
			password:'',
			showPassword:false,
			confirmPassword:'',
			showConfirmPassword:false,
			beaconMac:"",
			search: '',
			accessLevels: [
				{ index:0, level:"User" },
				{ index:1, level:"Administrator" },
				{ index:2, level:"System Administrator" },
			],
			deviceType: [
				{ id: 'Barcode', label: 'Barcode Reader'},
				{ id: 'BLE', label: 'BLE'},
				{ id: 'GNSS', label: 'GNSS'},
				{ id: 'GPS', label: 'GPS'},
				{ id: 'QR', label: 'QR Code Reader'},
				{ id: 'RFID', label: 'RFID Reader'},
			],
			selectedDeviceType:[],
			accessLevel:{},
			headers: [
				{ text: 'MAC', value: '_id' },
				{ text: 'Beacon Type', value: 'type' },
				{ text: 'Owner', value: 'owner' },
				{ text: 'Last Update', value: 'lastupdate' },
				{ text: 'Name', value: 'name' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			rowsAmount: [15, 20, 25, 50, -1],
			beaconList: [],
			footer:{
				'items-per-page-options':[15, 20, 25, 50, -1]
			},		  
			addDialog: false,
			newSaveError:"",
			editDialog: false,
			selectedBeacon: {},
			selectedEntity:[],
			entityList:[],
			currentBeacon: {},
			selfOwner: false,
			ownerid:'',
			beaconName: "",
			superUser: true,
			deleteError:'',
			
			showDeleted:false,
			
			itemDeleted:false,
			wasDeleted:false,
			showError:"",
			id:"",
		}),
		methods: {
			getAccessLevel(level){
				var result="";
				if (!level) level=0;
				this.accessLevels.forEach(function(item){
					if (item.index===level)
						result=item.level;
				})
				
				return result;
			},
			addItem: function() {
				this.beaconMac="";
				this.selectedBeacon={};
				this.addDialog=true;
				this.ownerid='';
				this.beaconName="";
				this.password="";
				this.selectedEntity=[];
				this.selectedDeviceType={};
				this.showPassword=false;
				this.confirmPassword="";
				this.showConfirmPassword=false;
				this.saveErrorMessage="";
			},
			editItem: function(item) {
			console.log(item);
				this.currentBeacon=item;
				this.editDialog=true;
				this.selectedEntity=item.owner;
				this.beaconMac=item._id;
				if (item.deleted)
				{
					this.wasDeleted=true;
					this.itemDeleted=true;
				}
				else
				{
					this.wasDeleted=false;
					this.itemDeleted=false;
				}
				this.selectedDeviceType=item.deviceType;
				this.beaconName=item.name;
				this.id=item.id;
				this.password=item.password;
				this.showPassword=false;
				this.confirmPassword=item.password;
				this.showConfirmPassword=false;
				this.saveErrorMessage="";
			},
			selectedOwner: function(item) {
				this.ownerid=item._id;
			},
			saveBeacon: function()
			{
				this.saveErrorMessage="";
				var valid=true;
				var beaconData={};
				if (this.beaconMac==="")
				{
					valid=false;
					this.saveErrorMessage="MAC address required";
				}
				else
				{
					beaconData._id=this.beaconMac;
				}
					
				if (valid)
				{
					if (!this.selectedEntity)
					{
						valid=false;
						this.saveErrorMessage="Owner required, please make sure all fields are completed before saving again.";
					}
					else
						beaconData.owner=this.selectedEntity;
				}
				if (valid)
				{
					if (!this.beaconName)
					{
						valid=false;
						this.saveErrorMessage="User name required, please make sure all fields are completed before saving again.";
					}
					else
						beaconData.name=this.beaconName;
				}
				if (valid)
				{
					if (!this.selectedDeviceType)
					{
						valid=false;
						this.saveErrorMessage="Device type required, please make sure all fields are completed before saving again.";
					}
					else
						beaconData.deviceType=this.selectedDeviceType;
				}
					
				if (valid)
				{
					beacon.addBeacon(beaconData, this.$store.getters.token).then(response => {
						if (response && response.data && response.data.status )
						{
							if (response.data.status==="ok" && response.data.access_token)
							{
								this.$store.commit('setToken', response.data.access_token);
								this.addDialog=false;
								this.getBeacons();
							}
							else
							{
								if (response.data.status==="error")
									this.saveErrorMessage=response.data.message;
							}
						}
						else
						{
							this.$store.commit('LogOut');
						}
					})
					.catch(error => {
						console.log(error);
						this.saveErrorMessage=error;
					});
				}
			},			
			updateBeacon: function()
			{
				var beaconData={};
				beaconData.id=this.beaconMac;
				beaconData.name=this.beaconName;
				beaconData.deviceType=this.selectedDeviceType;
				beaconData.owner=this.selectedEntity;
				if (this.wasDeleted&&!this.itemDeleted)
					beaconData.deleted=false;
					
				beacon.updateBeacon(beaconData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.editDialog=false;
						this.getBeacons();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			deleteItem: function(item) {
				beacon.deleteBeacon(item._id, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						//this.addDialog=false;
						this.getBeacons();
					}
					else
					{
						if (response && response.data && response.data.status && response.data.message)
						{
							this.showError=response.data.message
						}
						else
							this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.deleteError=error;
				});
			},
			changed: function(currentSelection){
				this.selectedTab=currentSelection
			},
			getBeacons: function() {
				beacon.beacons(this.$store.getters.token, this.showDeleted).then(response => {
					if (response && response.data && response.data.Beacons)
					{
						this.beaconList=response.data.Beacons;
						console.log(response.data.Beacons);
						if (response.data.entities&&response.data.entities.length>0)
							this.entityList=response.data.entities;
						else
							this.entityList=[{_id:response.data.users[0].entity,name:response.data.users[0].ownerName}];
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			getOwnerName: function(item)
			{
				var result="";
				console.log(this.entityList);
				for (var i=0; i<this.entityList.length&&result===""; i++)
				{
					if (this.entityList[i]._id===item)
						result=this.entityList[i].name;
				}
				return result;
			},
			getOwnerNames: function(item)
			{
				var result="";
				if (item)
				{
					if (item.length)
					{
						for (var i=0;i<item.length;i++)
						{
							if (item[i]!=="")
							{
								if (result!="") result+=", ";
								result+=this.getOwnerName(item[i]);
							}
						}
					}
					else
						result=this.getOwnerName(item);
				}
					
				return result;
			},
			getDateTime(item){
				if (item)
				{
					var curDate=new Date(item);
					
					return curDate.toLocaleString('en-AU');
				}
				return "";
			}
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
			filteredData(){
				var result=this.beaconList.filter(item => (item.deleted !== true || this.showDeleted));
				return result;
			},
			filteredEntityData(){
				var result=this.entityList.filter(item => (!item.deleted));
				return result;
			},
			filteredAccessLevel(){
				var result=this.accessLevels.filter(item => (item.index <= this.$store.state.access));
				return result;
			},
		},
		created() {
			document.title="Tract - Beacon Settings";
			this.getBeacons();
		}
	}
</script>
