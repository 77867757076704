<template>
	<div style="margin-top:15px; margin-left:15px;">				
		<div>
			<v-row v-if="showMenu" style="margin-right:10px;">
				<v-navigation-drawer
					permanent
				>
					<v-treeview :items="filteredItems" activateable open-all>
						<template v-slot:label="item">
							<div v-if="!item.item.children&&item.item.name===selectedTab" @click="changed(item.item)" style="background-color:green;color:white;padding-top:5px;">
								<div>
									<v-icon style="color:white">{{ item.item.icon }}</v-icon>
									{{item.item.title}}
								</div>
							</div>
							<div v-if="!item.item.children&&item.item.name!==selectedTab" @click="changed(item.item)">
								<v-icon>{{ item.item.icon }}</v-icon> 
								{{item.item.title}}
							</div>
							<div v-if="item.item.children"  style="font-style: italic; font-weight: bold;">
								<v-icon>{{ item.item.icon }}</v-icon> 
								{{item.item.title}}
							</div>
						</template>
					</v-treeview>
				</v-navigation-drawer>		
				<v-col>
					<v-app-bar style="margin-top:-10px;">
							<v-row>
								<v-col style="text-align:left;">
									<v-icon style="margin-right:10px">{{selectedTabIcon}}</v-icon> {{selectedTabLabel}}
								</v-col>
							</v-row>
					</v-app-bar>
					<user v-if="selectedTab==='user'"/>
					<entity v-if="selectedTab==='entity'"/>
					<detector v-if="selectedTab==='detector'"/>
					<beacon v-if="selectedTab==='beacon'"/>
				</v-col>
			</v-row>			
			<v-row v-else style="margin-right:10px;"> 
				<v-col>
					<v-app-bar style="margin-top:-10px;">
							<v-row>
								<v-col style="text-align:left;">
									<v-icon style="margin-right:10px">{{selectedTabIcon}}</v-icon> {{selectedTabLabel}}
								</v-col>
							</v-row>
					</v-app-bar>
					<user v-if="selectedTab==='user'"/>
					<entity v-if="selectedTab==='entity'"/>
					<detector v-if="selectedTab==='detector'"/>
					<beacon v-if="selectedTab==='beacon'"/>
				</v-col>
			</v-row>			
		</div>
	</div>
</template>
<script>
	import entity from '@/components/Setup/entity';
	import user from '@/components/Setup/user';
	import detector from '@/components/Setup/detector';
	import beacon from '@/components/Setup/beacon';
	export default {
		name: 'Setup',
        props: ['showMenu'],
		components: {
			entity,
			user,
			detector,
			beacon
		},
		data: () => ({
			selectedMainTab:'access',
			selectedTab:'user',
			selectedTabLabel:'User',
			selectedTabIcon:'mdi-account-multiple',
			items: [
				{id:1, title: 'Access', name: 'Access', children:[
						{ id:2, title: 'User', icon: 'mdi-account-multiple', name:'user', access:0 },
						{ id:3, title: 'Entity', icon: 'mdi-office-building', name:'entity', access:1 },
					]},
				{id:4, title: 'System', name: 'System', children:[
						{ id:5, title: 'Detector', icon: 'mdi-signal-variant', name:'detector', access:2 },
						{ id:6, title: 'Beacon', icon: 'mdi-antenna', name:'beacon', access:2 },
					]},
			],			
		}),
		methods: {
			changed: function(item){
				this.selectedTabIcon=item.icon;
				this.selectedTabLabel=item.title;
				this.selectedTab=item.name;
			},
			changedMain: function(currentSelection){
				this.selectedMainTab=currentSelection;
				if (currentSelection==="access")
					this.selectedTab="users";
				if (currentSelection==="system")
					this.selectedTab="detector";
			},
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
					
					console.log(this.$store.state.access);
				return result;
			},
			filteredItems() {
				var result=[];
				var access=0;

				if (this.$store.state.access)
					access=this.$store.state.access;
				
				
				this.items.forEach(function(item){
					var filterChildren=[];
					var minAccess=2;
					item.children.forEach(function(child){
						if (access>=child.access)
						{
							filterChildren.push(child);
							if (child.access<minAccess)
								minAccess=child.access;
						}

					});
					item.children=filterChildren;
					if (access>=minAccess)
						result.push(item);
				});
				
				return result;
			}
		},
		created() {
		}
	}
</script>
