<template>
	<div id="app" style="height: 100%; width: 100%; margin-top:5px;">
		<v-row class="hidden-md-and-down" v-if="showMenu">
			<v-col cols="3">
				<v-card>
					<v-card-title>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							title="Search/Filter"
							single-line
							hide-details
						></v-text-field>
						<v-icon
							medium
							class="mr-2"
							@click="addItem()"
							color="green"
							title="Add a new user"
							style="margin-left:20px;"
						>
							mdi-plus
						</v-icon>
					</v-card-title>
					<v-data-table
						:items="filteredData"
						:items-per-page="5"	
						:headers="headers"
						:footer-props="{
							showFirstLastPage: true,
							firstIcon: 'mdi-arrow-collapse-left',
							lastIcon: 'mdi-arrow-collapse-right',
							prevIcon: 'mdi-minus',
							nextIcon: 'mdi-plus'
						}"					>
						<template v-slot:item="{ item }">
							<tr :data-id="item.id" style="text-align:left;">
								<td :title="item.name+' Battery: '+item.battery+'%'+' Updated: '+getDateTime(item.lastupdate)" v-if="item.name">
									<v-checkbox v-model="item.selected" :label="item.name" style="text-align:left; margin-top:5px;margin-bottom:-15px;" color="item.colour" @change="selectionChanged(item)">
									</v-checkbox>
								</td>
								<td :title="item._id+' Battery: '+item.battery+'%'+' Updated: '+getDateTime(item.lastupdate)" v-else>
									<v-checkbox v-model="item.selected" :label="item._id" style="text-align:left; margin-top:5px;margin-bottom:-15px;" color="item.colour"  @change="selectionChanged(item)">
									</v-checkbox>
								</td>
							</tr>
						</template>
					</v-data-table>
					<div>
						<v-checkbox v-model="todayOnly" label="Today" @change="selectionChanged({_id:'today'})" style="margin-left:10px;" v-if="selections().length>0">
						</v-checkbox> 
						<div v-if="selections().length>0&&todayOnly">
							First activity: {{getDateTime(selections()[0].firstActivity)}}
						</div>
						<div v-if="selections().length>0&&todayOnly">
							Last activity: {{getDateTime(selections()[0].lastActivity)}}
						</div>
						<div v-if="selections().length>0">
							Last Update: {{getDateTime(selections()[0].lastupdate)}}
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols=9>
				<v-img
					height="100%"
					width="100%"
				>
					<l-map
							v-if="showMap"
							:zoom="zoom"
							:center="center"
							:options="mapOptions"
							style="height:90vh;"
							@update:zoom="zoomUpdate"
					>
						<l-tile-layer
							:url="url"
							:attribution="attribution"
						/>
						<l-marker v-for="(marker, index) in displayMarkers" :lat-lng="marker.position" :key="index" :icon="getIcon(marker.index,marker.colour)">
							<l-popup>
								<div>
									{{marker.name}}
								</div>
								<div>
									{{getDateTime(marker.detected)}}
								</div>
							</l-popup>
						</l-marker>
						
					</l-map>
				</v-img>
			</v-col>
		</v-row>  
		<v-row v-else class="hidden-md-and-down" >
			<v-col cols=12>
				<v-img
					height="100%"
					width="100%"
				>
					<l-map
							v-if="showMap"
							:zoom="zoom"
							:center="center"
							:options="mapOptions"
							style="height:90vh;"
							@update:zoom="zoomUpdate"
					>
						<l-tile-layer
							:url="url"
							:attribution="attribution"
						/>
						<l-marker v-for="(marker, index) in displayMarkers" :lat-lng="marker.position" :key="index" :icon="getIcon(marker.index,marker.colour)">
							<l-popup  v-if="selections().length>0">
								<div>
									{{marker.name}}
								</div>
								<div>
									Index: {{marker.index}}
								</div>
								<div>
									{{getDateTime(marker.detected)}}
								</div>
							</l-popup>
						</l-marker>
						
					</l-map>
				</v-img>
			</v-col>
		</v-row>  
		<v-row class="hidden-lg-and-up" v-if="showMenu">
			<v-col cols="12">
				<v-card>
					<v-card-title>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							title="Search/Filter"
							single-line
							hide-details
						></v-text-field>
						<v-icon
							medium
							class="mr-2"
							@click="addItem()"
							color="green"
							title="Add a new user"
							style="margin-left:20px;"
						>
							mdi-plus
						</v-icon>
					</v-card-title>
					<v-data-table
						:items="filteredData"
						:items-per-page="5"				
						:search="search"
						:headers="headers"
					>
						<template v-slot:item="{ item }">
							<tr :data-id="item.id" style="text-align:left;">
								<td :title="item.name+' Battery: '+item.battery+'%'+' Updated: '+getDateTime(item.lastupdate)" v-if="item.name">
									<v-checkbox v-model="item.selected" :label="item.name" style="text-align:left; margin-top:5px;margin-bottom:-15px;" @change="selectionChanged(item)">
									</v-checkbox>
									<div style="width:5px;height:5px;" class="item.colour">
									</div>
								</td>
								<td :title="item._id+' Battery: '+item.battery+'%'+' Updated: '+getDateTime(item.lastupdate)" v-else>
									<v-checkbox v-model="item.selected" :label="item._id" style="text-align:left; margin-top:5px;margin-bottom:-15px;" @change="selectionChanged(item)">
									</v-checkbox>
									<div style="width:5px;height:5px" class="item.colour">
									</div>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
			<v-col cols=12>
				<v-img
					height="100%"
					width="100%"
				>
					<l-map
							v-if="showMap"
							:zoom="zoom"
							:center="center"
							:options="mapOptions"
							style="height:90vh;"
							@update:zoom="zoomUpdate"
					>
						<l-tile-layer
							:url="url"
							:attribution="attribution"
						/>
						<l-marker v-for="(marker, index) in displayMarkers" :lat-lng="marker.position" :key="index" :icon="getIcon(marker.index,marker.colour)">
							<l-popup  v-if="selections().length>0">
								<div>
									{{marker.name}}
								</div>
								<div>
									Index: {{marker.index}}
								</div>
								<div>
									{{getDateTime(marker.detected)}}
								</div>
							</l-popup>
						</l-marker>
						
					</l-map>
				</v-img>
			</v-col>
		</v-row>  
		<v-row v-else class="hidden-lg-and-up" >
			<v-col cols=12>
				<v-img
					height="100%"
					width="100%"
				>
					<l-map
							v-if="showMap"
							:zoom="zoom"
							:center="center"
							:options="mapOptions"
							style="height:90vh;"
							@update:zoom="zoomUpdate"
					>
						<l-tile-layer
							:url="url"
							:attribution="attribution"
						/>
						<l-marker v-for="(marker, index) in displayMarkers" :lat-lng="marker.position" :key="index" :icon="getIcon(marker.index,marker.colour)">
							<l-popup  v-if="selections().length>0">
								<div>
									{{marker.name}}
								</div>
								<div>
									Index: {{marker.index}}
								</div>
								<div>
									{{getDateTime(marker.detected)}}
								</div>
							</l-popup>
						</l-marker>
						
					</l-map>
				</v-img>
			</v-col>
		</v-row>  
	</div>
</template>
<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
	import beacon from '@/services/beacon.js'

	export default {
		name: 'Location',
        props: ['showMenu'],
		components: {
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
		},
		data: () => ({
			search:"",
			zoom: 8,
			center: [-33.76, 151],
			headers: [
				{ text: 'Trackers', value: '_id' },
				{ text: 'Name', value: 'name', align: ' d-none'  },
			],
			defaultIcon: L.icon({
				iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
				shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
				iconSize:     [19, 47],
				shadowSize:   [25, 32],
				iconAnchor:   [11, 46],
				shadowAnchor: [4, 31],
				popupAnchor:  [-3, -76]
			}),
			clicktoshow:'click to show popup',
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution:
				'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | Tract - IoT X3 Pty Ltd',
			withPopup: [-33.768960, 150.934710],
			withTooltip: [-33.746608, 150.695488],
			withTooltip2: [-30.515598, 151.647781],
			withTooltip3: [-33.772442, 151.165176],
			displayMarkers: [],
			currentZoom: 7,
			currentCenter: [-33.768960, 150.934710],
			showParagraph: false,
			mapOptions: {
				zoomSnap: 0.5
			},
			showMap: true,
			beaconList:[],
			todayOnly: true,
		}),
		methods: {
			zoomUpdate(zoom) {
				this.currentZoom = zoom;
			},
			showLongText() {
				this.showParagraph = !this.showParagraph;
			},
			innerClick() {
				alert("Click!");
			},
			getIcon(number,colour)
			{
				if (!colour)
					colour="orange";
				var ldivicon=L.divIcon({
					className: "number-icon "+colour,
					iconSize: [35, 25],
					iconAnchor: [12, 20],
					popupAnchor: [3, -40],
					html: number   
				});
				
				return ldivicon;
			},
			getBeacons: function() {
				beacon.beacons(this.$store.getters.token, this.showDeleted).then(response => {
					if (response && response.data && response.data.Beacons)
					{
						this.beaconList=response.data.Beacons;
						this.selectionChanged();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			},
			getDateTime(item){
				if (item)
				{
					var curDate=new Date(item);
					
					return curDate.toLocaleString('en-AU');
				}
				return "";
			},			
			checkDuplicate(items,longitude,latitude)
			{
				var duplicate=false;
				for (var j=0;j<items.length&&!duplicate;j++)
				{
					if (items[j].longitude===longitude)
					{
						duplicate=true;
					}
					if (items[j].latitude===latitude)
					{
						duplicate=true;
					}
				}
				
				return duplicate;
			},
			selectionChanged(item)
			{
				var result=[];
				var updatedResult=[];
				var colours=["green","blue","orange","red","pink","purple","cyan","yellow","lime","magenta","brown","black","white"];
				var colour=colours[0];
				var colourIndex=0;
				var me=this;
				var avgLat=0;
				var avgLon=0;
				var minLat=0;
				var minLon=0;
				var maxLat=0;
				var maxLon=0;
				if (item)
				{
					this.beaconList.forEach(
						function(beacon)
						{
							if (beacon._id!==item._id)
							{
								if (item._id!=='today') beacon.selected=false;
							}
						}
					);
					
					if (this.selections().length>0)
					{
						this.beaconList.forEach(
							function(beacon)
							{
								colour=colours[colourIndex++];
								if (colourIndex>=colours.length)
								{
									colourIndex=0;
								}
								beacon.colour=colour;
								delete beacon.firstActivity;
								delete beacon.lastActivity;
								if (beacon.selected&&beacon.detections)
								{
									var index=0;
									for (var i=0;i<beacon.detections.length;i++)
									{
										if (beacon.detections[i].longitude&&beacon.detections[i].latitude&&parseFloat(beacon.detections[i].longitude)!==0.0&&parseFloat(beacon.detections[i].latitude)!==0.0&&beacon.detections[i].detected)
										{
											var curDetection={};
											index++;
											curDetection.detected=beacon.detections[i].detected;
											if (curDetection.detected>=(new Date(Date.now()-24*60*60*1000)))
											{
												colour="green";
												if (beacon.firstActivity)
													beacon.lastActivity=beacon.detections[i].detected
												else
													beacon.firstActivity=beacon.detections[i].detected;
											}
											else
											{
												if (curDetection.detected>=(new Date(Date.now()-2*24*60*60*1000)))
												{
													colour="orange";
												}
												else
												{
													colour="red";
												}
											}
											curDetection.index=index;
											var longitude=parseFloat(beacon.detections[i].longitude).toFixed(4);
											var latitude=parseFloat(beacon.detections[i].latitude).toFixed(4);
											var duplicate=me.checkDuplicate(result,longitude,latitude);
											while (duplicate)
											{
												if (duplicate)
												{
													longitude=longitude+Math.rand()*0.0001;
													latitude=latitude+Math.rand()*0.0001;
												}
												duplicate=me.checkDuplicate(result,longitude,latitude);
											}
											curDetection.position=[latitude,longitude];
											if (!longitude||avgLon===0) avgLon=longitude;
											if (!latitude||avgLat===0) avgLat=latitude;
											avgLon=(parseFloat(avgLon)+parseFloat(longitude))/2.0;
											avgLat=(parseFloat(avgLat)+parseFloat(latitude))/2.0;
											if (minLat==0||latitude<minLat)
												minLat=latitude;
											if (maxLat==0||latitude>maxLat)
												maxLat=latitude;
											if (minLon==0||longitude<minLon)
												minLon=longitude;
											if (maxLon==0||longitude>maxLon)
												maxLon=longitude;
											curDetection.id=beacon._id;
											curDetection.colour=colour;
											if (!beacon.name)
											{
												curDetection.name=beacon._id;
											}
											else
											{
												curDetection.name=beacon.name;
											}
											if (me.todayOnly&&colour==="green"||!me.todayOnly)
												result.push(curDetection);
										}
									}
								}
								updatedResult.push(beacon);								
							});
					}
				}
				if (this.selections().length===0)
				{
					result=[];
					updatedResult=[];
					colourIndex=0;
					this.beaconList.forEach(
						function(beacon)
						{
							colour=colours[colourIndex++];
							if (colourIndex>=colours.length)
								colourIndex=0;
							beacon.colour=colour;
							updatedResult.push(beacon);
							var found=false;
							
							if (beacon.detections)
							{
								for (var i=beacon.detections.length-1;i>=0&&!found;i--)
								{
									if (beacon.detections[i].longitude&&beacon.detections[i].latitude&&parseFloat(beacon.detections[i].longitude)!==0.0&&parseFloat(beacon.detections[i].latitude)!==0.0)
									{
										found=true;
										var curDetection={};
										curDetection.detected=beacon.detections[i].detected;
										var longitude=parseFloat(beacon.detections[i].longitude).toFixed(4);
										var latitude=parseFloat(beacon.detections[i].latitude).toFixed(4);
										var duplicate=me.checkDuplicate(result,longitude,latitude);
										while (duplicate)
										{
											if (duplicate)
											{
												longitude=longitude+Math.rand()*0.0001;
												latitude=latitude+Math.rand()*0.0001;
											}
											duplicate=me.checkDuplicate(result,longitude,latitude);
										}
										curDetection.position=[latitude,longitude];
										if (!longitude||avgLon===0) avgLon=longitude;
										if (!latitude||avgLat===0) avgLat=latitude;
										avgLon=(parseFloat(avgLon)+parseFloat(longitude))/2.0;
										avgLat=(parseFloat(avgLat)+parseFloat(latitude))/2.0;
										if (minLat==0||latitude<minLat)
											minLat=latitude;
										if (maxLat==0||latitude>maxLat)
											maxLat=latitude;
										if (minLon==0||longitude<minLon)
											minLon=longitude;
										if (maxLon==0||longitude>maxLon)
											maxLon=longitude;
										curDetection.id=beacon._id;
										curDetection.colour=colour;
											
										if (!beacon.name)
										{
											curDetection.name=beacon._id;
											curDetection.index=beacon._id.substring(0,3);
										}
										else
										{
											curDetection.name=beacon.name;
											curDetection.index=beacon.name.substring(0,3);
										}
										result.push(curDetection);
									}
								}
							}
						}
					);
				}
				this.displayMarkers=result;
				this.beaconList=updatedResult;
				if (minLon && maxLon && minLon!==0&& maxLon!==0 && minLat && maxLat && minLat!==0&& maxLat!==0)
				{
					this.center=[(parseFloat(maxLat)+parseFloat(minLat))/2,(parseFloat(maxLon)+parseFloat(minLon))/2];
				}
				if (Math.abs(maxLon-minLon)<0.25&&Math.abs(maxLat-minLat)<0.25)
				{
					this.zoom=12;
				}
				else
				{
					if (Math.abs(maxLon-minLon)<0.5&&Math.abs(maxLat-minLat)<0.5)
						this.zoom=10;
					else
					{
						if (Math.abs(maxLon-minLon)<1&&Math.abs(maxLat-minLat)<1)
							this.zoom=8;
						else
							this.zoom=7;
					}
				}
			},
			selections(){
				var result=this.beaconList.filter(item => item.selected === true);
				return result;
			},
		},			
		computed: {
			darkMode() {
				return this.$store.getters.darkMode;
			},
			filteredData(){
				var result=this.beaconList.filter(item => item.deleted !== true);
				return result;
			},
		},
		created() {
			document.title="Tract - Locate";
			this.getBeacons();
		}	
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
	.number-icon
	{
		text-align:center;
		color:White; 
	}
</style>