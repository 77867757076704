<template>
  <v-app>
		<v-app-bar
			app
			color="green"
			v-if="authenticated"
		>
			<div>
				<v-icon title="Settings menu" @click="showMenu = !showMenu">mdi-menu</v-icon> 
			</div>
			<v-row class="hidden-md-and-down" style="margin-left:15px">
					<div
					v-for="item in items"
					:key="item.id"
					@click="tabChange(item)"
					style="margin-left:5px;"
					
					>
						<v-btn v-if="item.id===mainTab" style="width:160px; background-color:green; color:white;">
							<v-icon style="margin-right:5px;">{{item.icon}}</v-icon> {{ item.name }}
						</v-btn>
						<v-btn v-if="item.id!==mainTab&&access>=item.accessLevel" style="width:160px;">
							<v-icon style="margin-right:5px;">{{item.icon}}</v-icon> {{ item.name }}
						</v-btn>
					</div>
			</v-row>
			
			<div class="hidden-md-and-down" style="position:absolute;right:20px;">
				<v-row>
					<v-icon @click="toggletheme" title="Toggle between Light and Dark Display Mode">mdi-monitor-eye</v-icon>
					<v-icon @click="unauthorise" title="Log Out" style="margin-left:10px">mdi-logout</v-icon>
					<div class="d-flex align-center;" style="background-color:white; padding:5px;padding-left:5px;padding-right:5px; margin-left:10px;">
						<v-img
							alt="Vuetify Name"
							contain
							min-width="150"
							src="/tract full.png"
							width="150"
						/>
					</div>
				</v-row>
			</div>
			<div class="hidden-lg-and-up" style="position:absolute;right:20px;">
				<v-row>
					<v-icon @click="toggletheme" title="Toggle between Light and Dark Display Mode">mdi-monitor-eye</v-icon>
					<v-icon @click="unauthorise" title="Log Out" style="margin-left:10px">mdi-logout</v-icon>
					<div class="d-flex align-center;" style="background-color:white; padding:5px;padding-left:5px;padding-right:5px; margin-left:10px;">
						<v-img
							alt="Vuetify Name"
							contain
							min-width="120"
							src="/tract full.png"
							width="120"
						/>
					</div>
				</v-row>
			</div>
		</v-app-bar>
		<v-app-bar
			app
			v-else
		>
			<v-icon @click="toggletheme" title="Toggle between Light and Dark Display Mode" style="position:absolute;right:10px;">mdi-monitor-eye</v-icon>
		</v-app-bar>
		<v-main v-if="authenticated" style="padding-top: 5px;">
			<v-container class="hidden-lg-and-up" style="background-color:green; margin-top:-55px; padding:10px;">
				<v-row no-gutters >
					<v-col cols=3 no-gutters style="text-align:left;">
					</v-col>
					<v-col cols=3 no-gutters style="text-align:left;">
					</v-col>
					<v-col cols=6 style="text-align:right;">
						<v-icon @click="toggletheme" title="Toggle between Light and Dark Display Mode" >mdi-monitor-eye</v-icon>
						<v-icon @click="unauthorise" title="Log Out" >mdi-logout</v-icon>						
					</v-col>
				</v-row>
				<v-row no-gutters style="margin-top:15px;">
					<div
						v-for="item in items"
						:key="item.id"
						@click="tabChange(item)"
						style="margin-left:5px; margin-top:5px;"
					>
						<v-btn v-if="item.id===mainTab" style="width:160px; background-color:grey; color:white;">
							<v-icon style="margin-right:5px;">{{item.icon}}</v-icon> {{ item.name }}
						</v-btn>
						<v-btn v-if="item.id!==mainTab&&access>=item.accessLevel" style="width:160px;">
							<v-icon style="margin-right:5px;">{{item.icon}}</v-icon> {{ item.name }}
						</v-btn>
					</div>
				</v-row>
			</v-container>
			<Location v-if="mainTab===0" :showMenu="showMenu"/>
			<Setup v-if="mainTab===1" :showMenu="showMenu"/>
		</v-main>		
		<v-main 
			v-else
		>
			<login/>
		</v-main>
		<v-footer>
			IoT X3
		</v-footer>
	</v-app>
</template>

<script>
	import Location from '@/components/Location';
	import login 	from '@/components/LoginForm';
	import Setup	from '@/components/Setup';

	export default {
		name: 'App',
		components: {
			Location,
			login,
			Setup,
		},
		data() {
			return {
				authenticated2: this.$store.getters.authenticated,
				items: [
					{ id:0, name: "Locate", icon: "mdi-magnify", accessLevel:0 },
					{ id:1, name: "Setup", icon: "mdi-cog", accessLevel:0 },
					
				],
				drawer:false,
				group:null,
				tab:null,
				showMenu:true,
			};
		},  
		computed: {
			authenticated : function() {
				return this.$store.getters.authenticated
			},
			darkMode : function() {
				return this.$store.getters.darkMode
			},
			user : function() {
				return this.$store.getters.user
			},
			mainTab : function() {
				return this.$store.getters.mainTab
			},
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
		},
		watch: {
			authenticated: function() {
				this.tab=0;
				if (this.$store.getters.mainTab)
					this.tabChange(this.$store.getters.mainTab);
			}
		},
		methods: {
			tabChange(item) {
				if (this.tab!=item.id)
				{
					this.tab=item.id;
					this.$store.commit('setMainTab',item.id);
				}
				else
					this.showMenu=!this.showMenu;
			},
			setTab(id) {
				this.tab=id;
			},
			toggletheme() {
				this.$store.commit('setDarkmode',!this.darkMode);
				this.$router.go()				
			},
			unauthorise: function () {
					this.$store.commit('LogOut');
			},
			authorise: function () {
					let username = "test"
					let password = "test1"
					this.$store.commit('setUser', { username, password })
						.catch(err => {
							console.log(err)
							//this.snackbar = true
					})
			}
		},
		created() {
			var me=this;
			document.title="Tract";
			this.$vuetify.theme.dark=this.$store.getters.darkMode;
			if (this.$store.getters.darkMode)
				document.body.style.backgroundColor = "black"
			this.tab=this.$store.getters.mainTab;
			this.setTab(this.$store.getters.mainTab);
			this.items.forEach(function(item){
				if (item.id==me.$store.getters.mainTab)
				{
					me.tabChange(item);
				}
			});
		}
	}
</script>


