<template>
	<div>
		<v-card>
			<v-card-title					
				v-if="access>=1"
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					title="Search/Filter users"
					single-line
					hide-details
				></v-text-field>
				<v-icon
					medium
					class="mr-2"
					@click="addItem()"
					color="green"
					title="Add a new user"
					style="margin-left:20px;"
				>
					mdi-plus
				</v-icon>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="userList"
				:items-per-page="15"				
				:search="search"
			>
				<template v-slot:item="{ item}">
					<tr :data-id="item.id" style="text-align:left;">
						<td v-if="!item.deleted">{{ item.username }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.username }}</td>
						<td v-if="!item.deleted">{{ item.ownerName }}</td>
						<td v-if="item.deleted" style="color:red;">{{ getAccessLevel(item.access) }}</td>
						<td v-if="!item.deleted">{{ getAccessLevel(item.access) }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.ownerName }}</td>
						<td>
							<v-icon
								medium
								class="mr-2"
								@click="editItem(item)"
								color="green"
								:title="'Edit user '+item.username"
								v-if="item.access<=access"
							>
								mdi-border-color
							</v-icon>
							<v-icon
								medium
								v-if="item.username!==$store.state.user && access>=1 && item.id && item.id!=='' && !item.deleted && item.access<=access"
								@click="deleteUser(item)"
								:title="'Delete user '+item.username"
							>
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>	
		<v-row>
			<v-col v-if="access>=2" style="margin-top:-10px;">
				<v-checkbox
					v-model="showDeleted"
					label="Show Deleted"
					@change="getUsers"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-dialog
			v-model="addDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Add user</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="userName"
								label="Name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredData"
								item-text="name"
								item-value="_id"
								@change="selectedOwner"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-text-field
								v-model="password"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!password || 'This field is required']"
								:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showPassword ? 'text' : 'password'"
								label="Password"
								placeholder="Password"
								counter
								required
								@keydown.enter="login"
								@click:append="showPassword = !showPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-25px;margin-bottom:10px;">
							<v-text-field
								v-model="confirmPassword"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!confirmPassword || 'This field is required']"
								:append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showConfirmPassword ? 'text' : 'password'"
								label="Confirm Password"
								placeholder="confirmPassword"
								counter
								required
								@keydown.enter="login"
								@click:append="showConfirmPassword = !showConfirmPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="userAccessLevel"
								label="Access Level"
								:items="filteredAccessLevel"
								item-text="level"
								item-value="index"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions>
						<v-row>
							<v-col cols=12 style="text-align:right">
								<v-btn
									text
									@click="saveUser"
								>Save</v-btn>
								<v-btn
									text
									@click="addDialog = false"
								>Close</v-btn>
							</v-col>
							<v-col v-if="saveErrorMessage!==''" cols=12  style="text-align:left; background-color:red;">
								{{saveErrorMessage}}
							</v-col>
						</v-row>					
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
		<v-dialog
			v-model="editDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Edit user</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="userName"
								label="Name"
								item-text="name"
								item-value="name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col cols=6 v-if="access>=2" style="margin-top:-45px;">
							<v-checkbox
								v-model="itemDeleted"
								label="Deleted"
								v-if="wasDeleted"
							></v-checkbox>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="ownerid"
								label="Owner"
								:items="filteredData"
								item-text="name"
								item-value="_id"
								@change="selectedOwner"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-text-field
								v-model="password"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!password || 'This field is required']"
								:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showPassword ? 'text' : 'password'"
								label="Password"
								placeholder="Password"
								counter
								required
								@keydown.enter="login"
								@click:append="showPassword = !showPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-25px;margin-bottom:10px;">
							<v-text-field
								v-model="confirmPassword"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!confirmPassword || 'This field is required']"
								:append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showConfirmPassword ? 'text' : 'password'"
								label="Confirm Password"
								placeholder="confirmPassword"
								counter
								required
								@keydown.enter="login"
								@click:append="showConfirmPassword = !showConfirmPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="userAccessLevel"
								label="Access Level"
								:items="filteredAccessLevel"
								item-text="level"
								item-value="index"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="updateUser"
						>Save</v-btn>
						<v-btn
							text
							@click="editDialog = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
	</div>
</template>
<script>
	import user from '@/services/user.js'
	export default {
		name: 'user',

		components: {
		},
		data: () => ({
			saveErrorMessage:'',
			password:'',
			showPassword:false,
			confirmPassword:'',
			showConfirmPassword:false,
			userAccessLevel:{},
			search: '',
			accessLevels: [
				{ index:0, level:"User" },
				{ index:1, level:"Administrator" },
				{ index:2, level:"System Administrator" },
			],
			accessLevel:{},
			headers: [
				{ text: 'Name', value: 'username' },
				{ text: 'Owner', value: 'ownerName' },
				{ text: 'Access Level', value: 'access' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			rowsAmount: [15, 20, 25, 50, -1],
			userList: [],
			footer:{
				'items-per-page-options':[15, 20, 25, 50, -1]
			},		  
			addDialog: false,
			newSaveError:"",
			editDialog: false,
			selecteduser: {},
			selectedEntity:{},
			entityList:[],
			currentuser: {},
			selfOwner: false,
			ownerid:'',
			userName: "",
			superUser: true,
			deleteError:'',
			
			showDeleted:false,
			
			itemDeleted:false,
			wasDeleted:false,
			
			id:"",
		}),
		methods: {
			getAccessLevel(level){
				var result="";
				if (!level) level=0;
				this.accessLevels.forEach(function(item){
					if (item.index===level)
						result=item.level;
				})
				
				return result;
			},
			addItem: function() {
				this.selecteduser={};
				this.addDialog=true;
				this.ownerid='';
				this.userName="";
				this.password="";
				this.selectedEntity="";
				this.showPassword=false;
				this.confirmPassword="";
				this.showConfirmPassword=false;
				this.userAccessLevel=0;
				this.saveErrorMessage="";
			},
			editItem: function(item) {
				this.currentuser=item;
				this.editDialog=true;
				this.ownerid=item.entity;
				if (item.deleted)
				{
					this.wasDeleted=true;
					this.itemDeleted=true;
				}
				else
				{
					this.wasDeleted=false;
					this.itemDeleted=false;
				}
				this.userName=item.username;
				this.id=item.id;
				this.password=item.password;
				this.showPassword=false;
				this.confirmPassword=item.password;
				this.showConfirmPassword=false;
				this.userAccessLevel={};
				var me=this;
				this.saveErrorMessage="";
				this.accessLevels.forEach(function(accessItem)
				{
					if (accessItem.index===item.access||accessItem.index===0&&!item.access)
						me.userAccessLevel=accessItem;
				});
			},
			selectedOwner: function(item) {
				this.ownerid=item;
			},
			saveUser: function()
			{
				var valid=true;
				var userData={};
				if (this.password===""||this.password!==this.confirmPassword)
				{
					valid=false;
					if (this.password==="")
						this.saveErrorMessage="Password not set, please make sure all fields are completed before saving again.";
					else
						this.saveErrorMessage="Password does not match confirmation password.  Please update passwords before saving again.";
				}
				else
					userData.password=this.password;
					
				if (valid)
				{
					if (this.userAccessLevel>=0)
						userData.access=this.userAccessLevel;
					else
					{
						valid=false;
						this.saveErrorMessage="User access level not set, please make sure all fields are completed before saving again.";
					}
				}
				if (valid)
				{
					if (!this.ownerid)
					{
						valid=false;
						this.saveErrorMessage="Owner required, please make sure all fields are completed before saving again.";
					}
					else
						userData.entity=this.ownerid;
				}
				if (valid)
				{
					if (!this.userName)
					{
						valid=false;
						this.saveErrorMessage="User name required, please make sure all fields are completed before saving again.";
					}
					else
						userData.username=this.userName;
				}
					
				if (valid)
				{
					user.addUser(userData, this.$store.getters.token).then(response => {
						if (response && response.data && response.data.access_token)
						{
							this.$store.commit('setToken', response.data.access_token);
							this.addDialog=false;
							this.getUsers();
						}
						else
						{
							this.$store.commit('LogOut');
						}
					})
					.catch(error => {
						console.log(error);
						this.newSaveError=error;
					});
				}
			},			
			updateUser: function()
			{
				var userData={};
				userData.id=this.id;
				userData.username=this.userName;
				if (this.itemDeleted)
					userData.deleted=true;
				else
					userData.deleted=false;
				userData.password=this.password;
				userData.entity=this.ownerid;
				userData.access=this.userAccessLevel.index;
				user.updateUser(userData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.editDialog=false;
						this.getUsers();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			deleteUser: function(item) {
				user.deleteUser(item.id, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						//this.addDialog=false;
						this.getUsers();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.deleteError=error;
				});
			},
			changed: function(currentSelection){
				this.selectedTab=currentSelection
			},
			getUsers: function() {
				user.users(this.$store.getters.token, this.showDeleted).then(response => {
					if (response && response.data && response.data.users)
					{
						this.userList=response.data.users;
						if (response.data.entities&&response.data.entities.length>0)
							this.entityList=response.data.entities;
						else
							this.entityList=[{_id:response.data.users[0].entity,name:response.data.users[0].ownerName}];
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			}
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
			filteredData(){
				var result=this.entityList.filter(item => (item.deleted !== true || this.showDeleted));
				return result;
			},
			filteredAccessLevel(){
				var result=this.accessLevels.filter(item => (item.index <= this.$store.state.access));
				return result;
			},
		},
		created() {
			document.title="Tract - User Settings";
			this.getUsers();
		}
	}
</script>